import React from "react";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import Banner from "../components/Banner";

const NotFoundPage = () => (
  <Layout>
    <div>
      <Banner
        props={{
          image: "/img/outdoor-group-kettlebell-fitness-workout.jpg",
          imageAlt: "Outdoor fitness class with dumbbells",
          bannerType: "image",
          heading: "Page Not Found",
        }}
      />
      <div className="content position-relative has-background-white py-5p">
        <div className="container">
          <p>That page could not be found.</p>
          <p>
            <Link
              className="featured-link button is-primary"
              to="/"
            >
              Return Home
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
